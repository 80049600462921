<template>
    <transition name="fade">
        <div class="page">
            <div class="secondary-header">
                <div @click="() => { this.$router.push({path: '/channels'})}">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <path d="M15 6L9 12L15 18" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    Channels
                </div>

            </div>

            <div class="space"></div>

            <div class="content-grid">
                <Channel v-ripple v-for="(k,j) in stations" :key="j" :channel="k" />
            </div>

       </div>

    </transition>
  </template>

  <script>
    const Channel = () => import('@/components/Channel')

  export default {
    name: 'StationsList',

    components: {
      Channel,
    },

    watch: {
        // $route() {
        //   this.load()
        //   this.$nextTick(() => {
        //       window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        //   })
        // }
    },

    data: function(){
      return {
          stations: [],
        loading: true,
      }
    },

    methods: {
            async loadStations(){
                this.loading = true;
                const req = await fetch(`${process.env.VUE_APP_SERVER}/all-stations`)
                const data = await req.json()

                this.stations = window.shuffle(data.stations)

                this.loading = false;
          },

    },

    computed: { },

    mounted: function(){
      this.loadStations()
   }
  }

  </script>

<style scoped lang="scss">
.secondary-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 20px 0;
    z-index: 9999;
    background-color: rgba(#141414, 0.75);
    backdrop-filter: blur(10px);
    padding-left: 20px;
}
.space{
    height: 70px
}

</style>
<style lang="scss">
.content-grid{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px auto;
    width: 90%;
    justify-content: space-between;
    > a{
        overflow: hidden;
        margin: 5px 0;
        width: calc(50% - 5px);
        padding: 0;
        border-radius: 5px;
        >div{
            width: 100%!important;
            max-width: 100%!important;
        }
    }

}

</style>
